.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	cursor: pointer;
	&-icon {
		position: absolute;
		inset-block-start: 1.5rem;
		inset-inline-end: 1.5rem;
		width: 2rem;
		height: 2rem;
		cursor: pointer;
	}
	&-container {
		position: relative;
		background-color: #fff;
		border-radius: 2rem;
		padding: 3.5rem;
		width: 40rem;
		max-width: 90%;
		display: flex;
		flex-direction: column;
		cursor: default;
		overflow: hidden;
		video {
			width: 100%;
		}
	}
}
