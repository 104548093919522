.hero {
	position: relative;
	padding-block: 12rem;
	padding-inline: calc(2 * var(--padding-x));
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	background-image: url(../../assets/images/line-bg.png);
	background-repeat: repeat;
	@media screen and (max-width: 769px) {
		padding-block: 6rem;
	}
	&-right,
	&-left {
		&-image {
			z-index: 0;
			position: absolute;
			@media screen and (max-width: 1250px) {
				scale: 0.7;
			}
			@media screen and (max-width: 1024px) {
				scale: 0.5;
			}
			@media screen and (max-width: 500px) {
				scale: 0.35;
			}
		}
	}
	&-right {
		&-image {
			inset-inline-end: -13rem;
			max-width: 100%;
			max-height: 100%;
			inset-block-start: -2rem;
			@media screen and (max-width: 1420px) {
				inset-block-start: -8rem;
				inset-inline-end: -14rem;
			}
			@media screen and (max-width: 1250px) {
				inset-block-start: -8rem;
				inset-inline-end: -20rem;
			}
			@media screen and (max-width: 1024px) {
				inset-block-start: -10rem;
				inset-inline-end: -25rem;
			}
			@media screen and (max-width: 769px) {
				scale: 0.45;
				inset-block-start: -20rem;
				inset-inline-end: -7rem;
			}
			@media screen and (max-width: 500px) {
				inset-block-start: -20rem;
				inset-inline-end: -6.3rem;
			}
		}
	}
	&-left {
		&-image {
			scale: 1.2;
			inset-inline-start: -4rem;
			max-width: 100%;
			max-height: 100%;
			inset-block-start: 12rem;
			@media screen and (max-width: 1250px) {
				inset-block-start: 10rem;
				inset-inline-start: -4rem;
			}
			@media screen and (max-width: 1024px) {
				inset-block-start: 0rem;
				inset-inline-start: -8rem;
				scale: 0.9;
			}
			@media screen and (max-width: 769px) {
				scale: 0.5;
				inset-block-start: 2rem;
				inset-inline-start: -5.5rem;
			}
			@media screen and (max-width: 500px) {
				scale: 0.35;
				inset-block-start: 15rem;
				inset-inline-start: -6.75rem;
			}
		}
	}
	&-text {
		padding-inline: 2rem;
		display: flex;
		align-items: center;
		gap: 1rem;
		font-size: 3rem;
		font-weight: 300;
		text-transform: uppercase;
		letter-spacing: 1rem;
		color: var(--font-color-primary);
		z-index: 1;
		@media screen and (max-width: 900px) {
			font-size: 2.5rem;
		}
		@media screen and (max-width: 769px) {
			font-size: 2rem;
		}
		@media screen and (max-width: 500px) {
			font-size: 1.75rem;
			padding-inline: 0;
		}
	}
	&-title {
		padding-inline: 2rem;
		position: relative;
		font-size: 6.25rem;
		font-weight: 800;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.25rem;
		color: #fff;
		z-index: 1;
		text-shadow: -0.25rem -0.25rem 0 var(--font-color-primary), -0.25rem -0.1875rem 0 var(--font-color-primary),
			-0.25rem -0.125rem 0 var(--font-color-primary), -0.25rem -0.0625rem 0 var(--font-color-primary), -0.25rem 0 0 var(--font-color-primary),
			-0.25rem 0.0625rem 0 var(--font-color-primary), -0.25rem 0.125rem 0 var(--font-color-primary), -0.25rem 0.1875rem 0 var(--font-color-primary),
			-0.25rem 0.25rem 0 var(--font-color-primary), -0.25rem 0.3125rem 0 var(--font-color-primary), -0.25rem 0.375rem 0 var(--font-color-primary),
			-0.25rem 0.4375rem 0 var(--font-color-primary), -0.25rem 0.5rem 0 var(--font-color-primary), -0.25rem 0.5625rem 0 var(--font-color-primary),
			-0.25rem 0.625rem 0 var(--font-color-primary), -0.25rem 0.6875rem 0 var(--font-color-primary), -0.25rem 0.75rem 0 var(--font-color-primary),
			-0.25rem 0.8125rem 0 var(--font-color-primary), -0.25rem 0.875rem 0 var(--font-color-primary), 0.25rem -0.25rem 0 var(--font-color-primary),
			0.25rem -0.1875rem 0 var(--font-color-primary), 0.25rem -0.125rem 0 var(--font-color-primary), 0.25rem -0.0625rem 0 var(--font-color-primary),
			0.25rem 0 0 var(--font-color-primary), 0.25rem 0.0625rem 0 var(--font-color-primary), 0.25rem 0.125rem 0 var(--font-color-primary),
			0.25rem 0.1875rem 0 var(--font-color-primary), 0.25rem 0.25rem 0 var(--font-color-primary), 0.25rem 0.3125rem 0 var(--font-color-primary),
			0.25rem 0.375rem 0 var(--font-color-primary), 0.25rem 0.4375rem 0 var(--font-color-primary), 0.25rem 0.5rem 0 var(--font-color-primary),
			0.25rem 0.5625rem 0 var(--font-color-primary), 0.25rem 0.625rem 0 var(--font-color-primary), 0.25rem 0.6875rem 0 var(--font-color-primary),
			0.25rem 0.75rem 0 var(--font-color-primary), 0.25rem 0.8125rem 0 var(--font-color-primary), 0.25rem 0.875rem 0 var(--font-color-primary);
		@media screen and (max-width: 900px) {
			font-size: 5.25rem;
		}
		@media screen and (max-width: 769px) {
			font-size: 4.25rem;
		}
		@media screen and (max-width: 500px) {
			padding-inline: 0;
			font-size: 3.25rem;
		}
	}
	&-content {
		display: flex;
		justify-content: center;
		gap: 27rem;
		z-index: 1;
		@media screen and (max-width: 900px) {
			gap: 20rem;
		}
		@media screen and (max-width: 769px) {
			flex-direction: column;
			align-items: center;
			gap: 4rem;
		}
		@media screen and (max-width: 500px) {
			flex-direction: column;
			align-items: center;
			gap: 6rem;
		}
		div {
			width: 18%;
			max-width: 41rem;
			min-width: 20rem;
			font-size: 2rem;
			font-weight: 300;
			line-height: 1.75;
			display: flex;
			flex-direction: column;
			@media screen and (max-width: 500px) {
				font-size: 1.5rem;
			}
			p {
				display: inline-block;
				font-family: 'Schoolbell';
				span {
					display: inline-block;
					font-family: 'Schoolbell';
				}
			}
			&:nth-child(1) {
				margin-block-start: -4rem;
				@media screen and (max-width: 769px) {
					margin-block-start: -1rem;
					margin-inline-end: 10rem;
				}
				@media screen and (max-width: 500px) {
					margin-block-start: -1rem;
					margin-inline-end: 5rem;
					min-width: none;
					width: 10%;
				}
				img {
					max-width: 16rem;
					width: 100%;
					max-height: 100%;
					margin-top: 3rem;
					margin-bottom: -5rem;
					@media screen and (max-width: 769px) {
						align-self: center;
						max-width: 14rem;
						margin-bottom: -4rem;
					}
					@media screen and (max-width: 500px) {
						align-self: center;
						max-width: 12rem;
						margin-bottom: -4rem;
					}
				}
			}
			&:nth-child(2) {
				position: relative;
				margin-block-start: -5rem;
				margin-inline-end: 5rem;
				width: 24%;
				min-width: 25rem;
				max-width: 41rem;
				@media screen and (max-width: 769px) {
					margin-inline-end: 0;
					align-items: center;
					margin-block-start: 0;
					margin-inline-start: 10rem;
				}
				@media screen and (max-width: 500px) {
					margin-inline-end: 0;
					align-items: center;
					margin-block-start: 0;
					margin-inline-start: 5rem;
				}
				img {
					align-self: flex-end;
					max-width: 8rem;
					width: 100%;
					max-height: 100%;
					&:nth-of-type(1) {
						@media screen and (max-width: 769px) {
							position: absolute;
							inset-block-end: 90%;
							inset-inline-end: -2rem;
							max-width: 4rem;
							max-height: none;
							object-fit: contain;
						}
					}
					&:nth-of-type(2) {
						position: absolute;
						scale: 1.5;
						inset-block-end: 3rem;
						inset-inline-end: -5rem;
						@media screen and (max-width: 769px) {
							position: static;
							align-self: center;
							scale: 1.25;
						}
					}
				}
			}
			a {
				align-self: flex-start;
				display: flex;
				align-items: center;
				font-size: 1.5rem;
				text-transform: uppercase;
				gap: 1rem;
				font-family: 'Schoolbell';
				text-decoration: none;
				color: #da494c;
				img {
					position: static !important;
					align-self: center !important;
					width: 1.5rem !important;
					object-fit: contain;
					margin: 0 !important;
				}
			}
		}
	}
}

.underline {
	position: relative;
	img {
		position: absolute;
		width: auto !important;
		height: 0.75rem;
		inset-block-start: 80%;
		inset-inline-start: 0;
		margin: 0 !important;
	}
}

.top-arrow {
	position: relative;
	img {
		position: absolute;
		width: auto !important;
		height: 1.5rem;
		inset-block-start: 0;
		inset-inline-end: -1.5rem;
		margin: 0 !important;
	}
}

.selected {
	display: flex;
	position: relative;
	z-index: 1;
	&-img {
		align-self: flex-start !important;
		max-width: 8rem;
		width: 100%;
		max-height: 100%;
	}
	img {
		position: absolute;
		inset: 0;
		z-index: -1;
		transform: scale(1.1) translateY(0.5rem);
	}
}
