.footer {
	margin-top: 10rem;
	position: relative;
	padding-block: 4rem;
	padding-block-start: 20rem;
	padding-inline: 5%;
	display: flex;
	background-color: var(--bg-color-primary);
	justify-content: space-between;
	align-items: flex-start;
	z-index: 1;
	background-image: url(../../assets/icons/footer-bg.svg);
	background-position: 0 0%;
	background-size: cover;
	background-repeat: no-repeat;
	gap: 15%;
	@media screen and (max-width: 1100px) {
		gap: 10%;
	}
	@media screen and (max-width: 900px) {
		gap: 5%;
		padding-inline: var(--padding-x);
	}
	@media screen and (max-width: 800px) {
		gap: 2rem;
	}
	@media screen and (max-width: 769px) {
		margin-block-start: -2rem;
		padding-inline: calc(2 * var(--padding-x));
		flex-direction: column;
		gap: 4rem;
		padding-block-start: 45rem;
		background-image: url(../../assets/images/footer-bg-responsive.png);
		z-index: 0;
	}
	@media screen and (max-width: 500px) {
		flex-direction: column;
		padding-block-start: 25rem;
		gap: 3rem;
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 53rem;
		height: 11rem;
		background-image: url(../../assets/icons/footer-arc.svg);
		background-repeat: no-repeat;
		inset-inline-end: 0rem;
		inset-block-end: 85%;
		transform: rotate(4deg);
		z-index: 2;
		@media screen and (max-width: 1300px) {
			width: 46%;
			height: 25%;
			inset-block-end: 85%;
		}
		@media screen and (max-width: 1120px) {
			width: 41%;
			// height: 11rem;
			height: 27%;
			inset-block-end: 82%;
		}
		@media screen and (max-width: 1024px) {
			content: none;
		}
	}
	&-logo {
		max-width: 11rem;
		object-fit: contain;
		cursor: pointer;
	}
	&-menu {
		display: flex;
		flex-direction: column;
		gap: 1.25rem;
		@media screen and (max-width: 769px) {
			width: calc(50% - 1rem);
		}
		@media screen and (max-width: 500px) {
			width: 100%;
		}
		&-container {
			display: flex;
			gap: 2rem;
			flex-grow: 1;
			justify-content: space-between;
			@media screen and (max-width: 769px) {
				flex-direction: row;
				flex-wrap: wrap;
				gap: 4rem 2rem;
			}
			@media screen and (max-width: 500px) {
				flex-direction: column;
			}
		}
		&-wrapper {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			@media screen and (max-width: 769px) {
				width: 100%;
				flex-direction: row;
			}
			@media screen and (max-width: 500px) {
				width: 100%;
				flex-direction: column;
			}
		}
		h3 {
			font-weight: 800;
			font-size: 1.5rem;
			color: var(--font-color-secondary);
			text-transform: uppercase;
			cursor: default;
		}
		ul {
			list-style: none;
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 1.25rem;
			li {
				text-transform: uppercase;
				font-size: 1.375rem;
				font-weight: 800;
				color: var(--font-color-accent);
				cursor: pointer;
				a {
					text-decoration: none;
					color: var(--font-color-accent);
				}
			}
		}
	}
	&-providers {
		display: flex;
		gap: 1rem;
		align-items: center;
		object-fit: contain;
	}
}

.social {
	display: flex;
	gap: 1rem;
	&-media {
		width: 2rem;
		height: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid var(--font-color-accent);
		border-radius: 50%;
		cursor: pointer;
		&-icon {
			height: 60%;
			width: 60%;
			object-fit: contain;
		}
	}
}

.copyrights {
	background-color: var(--font-color-accent);
	padding-block: 1rem;
	padding-inline: 5%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.5rem;
	letter-spacing: 1px;
	font-weight: 300;
	gap: 1rem;
	@media screen and (max-width: 900px) {
		padding-inline: var(--padding-x);
	}
	@media screen and (max-width: 769px) {
		padding-inline: calc(2 * var(--padding-x));
	}
	@media screen and (max-width: 523px) {
		justify-content: center;
	}
	p {
		display: flex;
		gap: 0.5rem;
		align-items: center;
	}
	a {
		color: var(--bg-color-primary);
		text-decoration: none;
		cursor: pointer;
	}
}
