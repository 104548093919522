.container {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
	* {
		font-family: 'Schoolbell';
	}
	&:has(input:disabled) {
		color: #bababa;
		cursor: not-allowed;
	}
	label {
		font-size: 2rem;
		color: #000c78;
		@media screen and (max-width: 500px) {
			font-size: 1.75rem;
		}
	}
	.form-error {
		color: #fb5b5b;
		font-size: 1.75rem;
		padding-inline-start: 1rem;
	}
	.input-container {
		background: #fff;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 6rem;
		opacity: 1;
		border-radius: 2.125rem;
		position: relative;
		border: 4px solid #000c78;
		&:focus,
		&:focus-visible,
		&:focus-within {
			input::placeholder {
				text-align: start;
				color: #b3b7bf;
				font-size: 1.75rem;
				@media screen and (max-width: 500px) {
					font-size: 1.5rem;
				}
			}

			input:-ms-input-placeholder {
				text-align: start;
				color: #b3b7bf;
				font-size: 1.75rem;
				@media screen and (max-width: 500px) {
					font-size: 1.5rem;
				}
			}

			input::-webkit-input-placeholder {
				text-align: start;
				color: #b3b7bf;
				font-size: 1.75rem;
				@media screen and (max-width: 500px) {
					font-size: 1.5rem;
				}
			}
			input {
				text-align: start;
			}
		}
		&-error {
			border: 4px solid #fb5b5b;
			&:focus,
			&:focus-visible,
			&:focus-within {
				border: 4px solid #fb5b5b;
			}
		}
		input {
			background: transparent;
			height: 100%;
			width: 100%;
			border: none;
			padding-inline: 2rem;
			box-sizing: border-box;
			color: inherit;
			color: #000c78;
			font-size: 2rem;
			@media screen and (max-width: 500px) {
				font-size: 1.75rem;
			}
		}

		input:focus {
			outline: none;
		}

		input::placeholder {
			color: #b3b7bf;
			opacity: 1;
			font-size: 1.75rem;
			@media screen and (max-width: 500px) {
				font-size: 1.5rem;
			}
		}

		input:-ms-input-placeholder {
			color: #b3b7bf;
			font-size: 1.75rem;
			@media screen and (max-width: 500px) {
				font-size: 1.5rem;
			}
		}

		input::-ms-input-placeholder {
			color: #b3b7bf;
			font-size: 1.75rem;
			@media screen and (max-width: 500px) {
				font-size: 1.5rem;
			}
		}

		input[name='cvc'] {
			padding-inline-end: 2rem;
		}
		&-reverse {
			& input {
				text-align: start;
				padding-inline-start: 1rem;
			}
		}
		input:disabled {
			cursor: not-allowed;
		}
		label {
			background: transparent;
			font-size: 2.25rem;
			padding-inline-start: 1rem;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			white-space: nowrap;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			margin: 0.5rem 0;
			color: #000c78;
			@media screen and (max-width: 500px) {
				font-size: 2rem;
			}
		}
	}
}
