.stations {
	display: flex;
	flex-direction: column;
	gap: 7rem;
	background-color: #fff;
	width: 80%;
	align-self: center;
	padding: 8rem 4.75rem 8rem 0;
	border-radius: 8rem;
	position: relative;
	@media screen and (max-width: 1024px) {
		width: calc(100% - calc(2 * var(--padding-x)));
	}
	@media screen and (max-width: 769px) {
		padding-inline: var(--padding-x);
	}
	&-tabs {
		display: flex;
		padding-inline-start: 4.75rem;
		width: 100%;
		gap: 1rem;
		@media screen and (max-width: 769px) {
			padding-inline-start: var(--padding-x);
		}
		p {
			width: 100%;
			text-align: center;
			font-size: 2rem;
			cursor: pointer;
			padding-block: 2rem;
			border-radius: 1rem;
			&.selected-tab {
				background-color: #d1fffc;
			}
		}
	}
	&-title {
		position: relative;
		text-align: center;
		font-size: 3rem;
		font-weight: 800;
		text-transform: uppercase;
		color: #fff;
		letter-spacing: 0.375rem;
		text-shadow: -4px -4px 0 #b343d3, -4px -3px 0 #b343d3, -4px -2px 0 #b343d3, -4px -1px 0 #b343d3, -4px 0 0 #b343d3, -4px 1px 0 #b343d3,
			-4px 2px 0 #b343d3, -4px 3px 0 #b343d3, -4px 4px 0 #b343d3, -4px 5px 0 #b343d3, -4px 6px 0 #b343d3, -4px 7px 0 #b343d3, -4px 8px 0 #b343d3,
			0 -4px 0 #b343d3, 0 -3px 0 #b343d3, 0 -2px 0 #b343d3, 0 -1px 0 #b343d3, 0 0 0 #b343d3, 0 1px 0 #b343d3, 0 2px 0 #b343d3, 0 3px 0 #b343d3,
			0 4px 0 #b343d3, 0 5px 0 #b343d3, 0 6px 0 #b343d3, 0 7px 0 #b343d3, 0 8px 0 #b343d3, 4px -4px 0 #b343d3, 4px -3px 0 #b343d3, 4px -2px 0 #b343d3,
			4px -1px 0 #b343d3, 4px 0 0 #b343d3, 4px 1px 0 #b343d3, 4px 2px 0 #b343d3, 4px 3px 0 #b343d3, 4px 4px 0 #b343d3, 4px 5px 0 #b343d3,
			4px 6px 0 #b343d3, 4px 7px 0 #b343d3, 4px 8px 0 #b343d3;
	}
	&-container {
		display: flex;
		gap: 3.5rem;
		position: relative;
		@media screen and (max-width: 769px) {
			flex-direction: column;
		}
	}
	&-tab {
		&-list {
			list-style: none;
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			width: 50%;
			@media screen and (max-width: 900px) {
				width: 40%;
			}
			@media screen and (max-width: 769px) {
				width: 100%;
				flex-direction: row;
				overflow: scroll;
			}
			&-item {
				cursor: pointer;
				display: flex;
				flex-direction: column;
				gap: 1rem;
				padding: 2.25rem 8rem 2.25rem 9rem;
				@media screen and (max-width: 900px) {
					padding: 2.25rem 3.5rem;
				}
				p {
					font-family: 'Schoolbell';
					font-size: 1.75rem;
					position: relative;
					z-index: 1;
					&:first-child {
						img {
							display: none;
						}
					}
					&:last-child {
						font-family: 'Gemunu Libre';
						font-size: 1.75rem;
						font-weight: 400;
						width: max-content;
					}
					img {
						position: absolute;
						inset-inline: 0;
						inset-block-end: 50%;
						max-width: 100%;
						transform: translateY(50%);
						z-index: -1;
					}
				}
				img {
					@media screen and (max-width: 769px) {
						max-width: 15rem;
					}
				}
				h4 {
					font-family: 'Schoolbell';
					font-size: 2.125rem;
					font-weight: 200;
					span {
						font-family: 'Schoolbell';
						font-weight: 800;
					}
				}
			}
		}
		&-container {
			display: flex;
			flex-direction: column;
			gap: 3.5rem;
		}
		&-title {
			font-size: 2rem;
			font-weight: 800;
			color: var(--font-color-primary);
		}
		&-content {
			display: flex;
			flex-direction: column;
		}
		&-special {
			color: #9c80ff;
			font-size: 1.75rem;
			font-family: 'Schoolbell';
			margin-block-end: 0.5rem;
		}
		&-text {
			color: #0f0f0f;
			font-size: 1.75rem;
			font-family: 'Schoolbell';
			p {
				font-family: 'Schoolbell';
				color: #0f0f0f;
				font-size: 1.75rem;
				margin-block-end: 3rem;
			}
			&:nth-of-type(3) {
				margin-block-start: 2rem;
			}
		}
		&-age {
			display: flex;
			flex-direction: column;
			gap: 0.25rem;
			&-title {
				color: #ff6b25;
				font-size: 1.75rem;
				font-family: 'Schoolbell';
			}
			&-text {
				color: var(--font-color-primary);
				font-size: 1.75rem;
				font-family: 'Schoolbell';
				span {
					font-family: 'Schoolbell';
					color: #01708b;
				}
				ul {
					li {
						font-family: 'Schoolbell';
						color: #0f0f0f;
					}
				}
			}
		}
		&-benifits {
			display: flex;
			flex-direction: column;
			gap: 0.25rem;
			max-width: 50%;
			@media screen and (max-width: 769px) {
				max-width: 100%;
			}
			&-title {
				color: #01708b;
				font-size: 1.75rem;
				font-family: 'Schoolbell';
			}
			&-text {
				color: #0f0f0f;
				font-size: 1.75rem;
				font-family: 'Schoolbell';
			}
		}
		&-action {
			all: unset;
			align-self: flex-start;
			cursor: pointer;
			padding: 1rem 2.5rem;
			background-color: #fff;
			border-radius: 2rem;
			font-size: 1.5rem;
			font-weight: 800;
			color: var(--font-color-primary);
			text-transform: uppercase;
			border: 4px solid var(--font-color-primary);
			box-shadow: 4px 4px 0px 2px var(--font-color-primary);
			transition: all 0.3s;
			@media screen and (max-width: 769px) {
				align-self: center;
			}
			&:hover {
				transform: translate(0.25rem, 0.25rem);
				box-shadow: 4px 4px 0px 0px var(--font-color-primary);
			}
			&:disabled {
				opacity: 0.5;
				transform: none;
				box-shadow: 4px 4px 0px 2px var(--font-color-primary);
				cursor: not-allowed;
			}
		}
		&-image {
			position: absolute;
			inset-inline-end: 0;
			inset-block-end: 0;
			transform: translateX(40%);
			@media screen and (max-width: 1420px) {
				scale: 0.75;
				transform: translate(60%, 10%);
			}
			@media screen and (max-width: 1200px) {
				scale: 0.5;
				transform: translate(100%, 0%);
			}
			@media screen and (max-width: 1024px) {
				scale: 0.6;
				transform: translate(55%, 10%);
			}
			@media screen and (max-width: 900px) {
				scale: 0.5;
				transform: translate(75%, 5%);
			}
			@media screen and (max-width: 769px) {
				display: none;
			}
		}
	}
}

.selected {
	background-color: #d1fffc;
	position: relative;
	overflow: hidden;
	@media screen and (max-width: 769px) {
		overflow: visible;
		border-radius: 2rem;
	}
	&::before {
		content: '';
		background-color: #fff;
		position: absolute;
		inset-block-start: 29%;
		inset-block-end: 0;
		aspect-ratio: 1;
		inset-inline-end: 0;
		transform: translate(50%, 50%) rotate(-45deg);
		@media screen and (max-width: 769px) {
			content: none;
		}
	}
	&::after {
		content: '';
		background-color: #fff;
		position: absolute;
		inset-block-start: 0;
		inset-block-end: 29%;
		inset-inline-end: 0;
		transform: translate(50%, -50%) rotate(45deg);
		aspect-ratio: 1;
		@media screen and (max-width: 769px) {
			content: none;
		}
	}
	p {
		&:first-child {
			img {
				display: block;
			}
		}
		&:last-child {
			font-size: 1.75rem;
			font-weight: 800;
			width: max-content;
		}
	}
}
