.our-programs {
	margin-block-start: -15rem;
	padding-inline: calc(4 * var(--padding-x));
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 7rem;
	@media screen and (max-width: 900px) {
		padding-inline: var(--padding-x);
		gap: 2rem;
	}
	@media screen and (max-width: 769px) {
		margin-block-start: 0;
		flex-direction: column-reverse;
		padding-inline: var(--padding-x);
	}
	&-content {
		width: 45%;
		display: flex;
		flex-direction: column;
		gap: 4rem;
		@media screen and (max-width: 1024px) {
			width: 55%;
		}
		@media screen and (max-width: 769px) {
			width: 100%;
			gap: 2rem;
		}
		&-title {
			font-size: 3.5rem;
			font-weight: 800;
			text-transform: uppercase;
			color: #fff;
			letter-spacing: 0.25rem;
			text-shadow: -4px -4px 0 var(--font-color-primary), -4px -3px 0 var(--font-color-primary), -4px -2px 0 var(--font-color-primary),
				-4px -1px 0 var(--font-color-primary), -4px 0 0 var(--font-color-primary), -4px 1px 0 var(--font-color-primary),
				-4px 2px 0 var(--font-color-primary), -4px 3px 0 var(--font-color-primary), -4px 4px 0 var(--font-color-primary),
				-4px 5px 0 var(--font-color-primary), -4px 6px 0 var(--font-color-primary), -4px 7px 0 var(--font-color-primary),
				-4px 8px 0 var(--font-color-primary), 0 -4px 0 var(--font-color-primary), 0 -3px 0 var(--font-color-primary),
				0 -2px 0 var(--font-color-primary), 0 -1px 0 var(--font-color-primary), 0 0 0 var(--font-color-primary), 0 1px 0 var(--font-color-primary),
				0 2px 0 var(--font-color-primary), 0 3px 0 var(--font-color-primary), 0 4px 0 var(--font-color-primary), 0 5px 0 var(--font-color-primary),
				0 6px 0 var(--font-color-primary), 0 7px 0 var(--font-color-primary), 0 8px 0 var(--font-color-primary), 4px -4px 0 var(--font-color-primary),
				4px -3px 0 var(--font-color-primary), 4px -2px 0 var(--font-color-primary), 4px -1px 0 var(--font-color-primary),
				4px 0 0 var(--font-color-primary), 4px 1px 0 var(--font-color-primary), 4px 2px 0 var(--font-color-primary),
				4px 3px 0 var(--font-color-primary), 4px 4px 0 var(--font-color-primary), 4px 5px 0 var(--font-color-primary),
				4px 6px 0 var(--font-color-primary), 4px 7px 0 var(--font-color-primary), 4px 8px 0 var(--font-color-primary);
			@media screen and (max-width: 769px) {
				font-size: 3rem;
			}
		}
		&-text {
			font-family: 'Schoolbell';
			font-size: 1.75rem;
		}
		div {
			display: flex;
			gap: 1.5rem;
			img {
				min-width: 7rem;
				max-width: 7rem;
				max-height: 7rem;
				object-fit: contain;
				@media screen and (max-width: 900px) {
					min-width: 5rem;
					max-width: 5rem;
					max-height: 5rem;
				}
				@media screen and (max-width: 769px) {
					align-self: center;
				}
				@media screen and (max-width: 500px) {
					align-self: flex-start;
				}
			}
			p {
				position: relative;
				display: flex;
				flex-direction: column;
				gap: 1rem;
				font-size: 1.75rem;
				font-family: 'Schoolbell';
				color: var(--font-color-primary);
				@media screen and (max-width: 769px) {
					padding-inline-end: 2.75rem;
				}
				span {
					align-self: start;
					font-size: 1.75rem;
					font-family: 'Schoolbell';
					color: var(--font-color-primary);
				}
			}
			p {
				display: inline-block;
				font-family: 'Schoolbell';
				@media screen and (max-width: 769px) {
					padding-inline-end: 2.75rem;
				}
				span {
					display: inline-block;
					font-family: 'Schoolbell';
					margin-inline-start: 0.5rem;
				}
			}
		}
		&-wrapper {
			flex-direction: column;
			gap: 0.5rem;
		}
	}
	&-image {
		margin-block-start: -12rem;
		width: 40%;
		@media screen and (max-width: 769px) {
			width: 70%;
			align-self: flex-end;
		}
		@media screen and (max-width: 500px) {
			width: 80%;
			align-self: flex-end;
		}
		img {
			width: 100%;
		}
	}
}

.selected {
	position: relative;
	z-index: 1;
	img {
		position: absolute;
		inset: -3px;
		object-fit: contain;
		z-index: -1;
		transform: translate(-2px, -2px);
	}
}

.down-arrow {
	position: absolute;
	min-width: 4rem !important;
	width: 4rem !important;
	max-width: 4rem !important;
	height: auto !important;
	object-fit: contain !important;
	inset-block-start: 100% !important;
	inset-inline-start: 100% !important;
	transform: translate(25%, -25%) !important;
	@media screen and (max-width: 1024px) {
		scale: 0.75;
		transform: translate(25%, -50%) !important;
	}
}
