.modal {
	background-color: #0003;
	backdrop-filter: blur(2px);
	position: fixed;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	&-icon {
		position: absolute;
		inset-block-start: 3rem;
		inset-inline-end: 3rem;
		width: 2rem;
		height: 2rem;
		cursor: pointer;
		@media screen and (max-width: 500px) {
			inset-block-start: 1.5rem;
			inset-inline-end: 1.5rem;
		}
	}
	&-container {
		position: relative;
		background-color: #fff;
		border-radius: 5rem;
		padding-block: 5rem;
		padding-inline: 10rem;
		width: 74rem;
		max-width: 90%;
		display: flex;
		flex-direction: column;
		cursor: default;
		overflow: hidden;
		@media screen and (max-width: 769px) {
			padding-block: 3rem;
			padding-inline: 7rem;
		}
		@media screen and (max-width: 500px) {
			padding-inline: 3rem;
			border-radius: 3rem;
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4rem;
		@media screen and (max-width: 768px) {
			gap: 2rem;
		}
		div {
			display: flex;
			justify-content: space-between;
			gap: 4rem;
			@media screen and (max-width: 850px) {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 1rem;
			}
			.modal-content-text {
				align-items: start;
				font-family: 'Schoolbell';
				text-align: start;
				&:nth-child(1) {
					width: auto;
					flex-grow: 1;
					span {
						font-size: 1.75rem;
						color: var(--font-color-secondary);
						font-family: 'Schoolbell';
					}
				}
				&:nth-child(2) {
					width: auto;
					flex-shrink: 1;
					span {
						font-size: 1.75rem;
						font-family: 'Schoolbell';
						color: #01708b;
					}
				}
			}
		}
		&-text {
			color: var(--font-color-primary);
			font-size: 1.75rem;
			font-weight: 500;
			font-family: 'Schoolbell';
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			span {
				font-family: 'Schoolbell';
				color: #9c80ff;
				font-size: 1.75rem;
			}
		}
		&-title {
			font-size: 2.5rem;
			font-weight: 800;
			text-align: center;
			text-transform: uppercase;
			color: var(--font-color-secondary);
			letter-spacing: 0.35rem;
			text-shadow: -1px -1px var(--font-color-primary), -1px 0px var(--font-color-primary), -1px 1px var(--font-color-primary),
				-1px 2px var(--font-color-primary), -1px 3px var(--font-color-primary), 0px -1px var(--font-color-primary), 0px 0px var(--font-color-primary),
				0px 1px var(--font-color-primary), 0px 2px var(--font-color-primary), 0px 3px var(--font-color-primary), 1px -1px var(--font-color-primary),
				1px 0px var(--font-color-primary), 1px 1px var(--font-color-primary), 1px 2px var(--font-color-primary), 1px 3px var(--font-color-primary);
			@media screen and (max-width: 769px) {
				font-size: 2.25rem;
			}
		}
	}
	&-action {
		all: unset;
		cursor: pointer;
		padding: 1rem 2.5rem;
		background-color: #fff;
		border-radius: 2rem;
		font-size: 1.5rem;
		font-weight: 800;
		color: var(--font-color-primary);
		text-transform: uppercase;
		border: 4px solid var(--font-color-primary);
		box-shadow: 4px 4px 0px 2px var(--font-color-primary);
		transition: all 0.3s;
		&:hover {
			transform: translate(0.25rem, 0.25rem);
			box-shadow: 4px 4px 0px 0px var(--font-color-primary);
		}
	}
	&-decoration {
		&-one {
			position: absolute;
			inset-inline-start: 0;
			inset-block-start: 1rem;
			@media screen and (max-width: 769px) {
				width: 7rem;
			}
			@media screen and (max-width: 500px) {
				display: none;
			}
		}
		&-two {
			position: absolute;
			inset-inline-end: 0;
			inset-block-end: 1rem;
			@media screen and (max-width: 769px) {
				width: 5rem;
			}
			@media screen and (max-width: 500px) {
				display: none;
			}
		}
	}
}

.register-tabs {
	display: flex;
	justify-content: center;
	gap: 2rem;
	padding-block: 5rem;
}

.discounts-card {
	width: 100%;
	width: 17rem;
	padding: 2rem;
	border: 5px solid var(--font-color-primary);
	background-color: #fff;
	border-radius: 5rem;
	display: flex;
	flex-direction: column;
	gap: 2rem !important;
	align-items: center;
	flex-grow: 1;
	cursor: pointer;
	& > img {
		height: 7rem;
		object-fit: contain;
	}
	&-title {
		font-family: 'Schoolbell';
		font-size: 1.75rem;
		font-weight: 400;
		z-index: 1;
		position: relative;
		&-image {
			position: absolute;
			z-index: -1;
			inset-block-end: 50%;
			transform: translateX(-30%) translateY(50%);
		}
	}
	&-text {
		font-family: 'Schoolbell';
		font-size: 1.5rem;
		color: var(--font-color-primary);
		flex-grow: 1;
		&-list {
			list-style: lower-alpha;
			padding: 0;
			font-family: 'Schoolbell';
			color: var(--font-color-primary);
			font-size: 1.25rem;
			li {
				font-size: 1.5rem;
				font-weight: 500;
				font-family: 'Schoolbell';
				color: var(--font-color-primary);
				span {
					font-family: 'Schoolbell';
					font-weight: 500;
					font-size: 1.5rem;
					color: var(--font-color-secondary);
				}
			}
		}
	}
	&-link {
		align-self: flex-start;
		display: flex;
		align-items: center;
		font-size: 1.5rem;
		text-transform: uppercase;
		gap: 1rem;
		font-family: 'Schoolbell';
		text-decoration: none;
		color: #da494c;
		margin-block-start: auto;
		img {
			align-self: center !important;
			width: 1.5rem !important;
			object-fit: contain;
		}
	}
}
