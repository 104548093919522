.register {
	position: relative;
	padding-block: 14rem 2rem;
	padding-inline: calc(2 * var(--padding-x));
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	background-image: url(../../assets/images/line-bg.png);
	background-repeat: repeat;
	@media screen and (max-width: 769px) {
		padding-block: 12rem;
		padding-inline: 2rem;
	}
	&-first {
		position: absolute;
		inset-inline-start: -7rem;
		inset-block-end: calc(100% - 3rem);
		@media screen and (max-width: 1024px) {
			scale: 0.8;
		}
		@media screen and (max-width: 769px) {
			scale: 0.45;
			inset-inline-start: -10rem;
			inset-block-end: calc(100% - 4rem);
		}
		@media screen and (max-width: 600px) {
			inset-inline-start: -11rem;
			inset-block-end: calc(100% - 5rem);
		}
		@media screen and (max-width: 420px) {
			inset-inline-start: -12rem;
			inset-block-end: calc(100% - 5rem);
		}
	}
	&-second {
		position: absolute;
		inset-inline-end: -10rem;
		inset-block-start: calc(100% - 5rem);
		@media screen and (max-width: 900px) {
			scale: 0.8;
		}
		@media screen and (max-width: 769px) {
			scale: 0.45;
			inset-block-start: calc(100% - 3rem);
			inset-inline-end: -5rem;
		}
		@media screen and (max-width: 600px) {
			scale: 0.45;
			inset-block-start: calc(100% - 3rem);
			inset-inline-end: -4rem;
		}
		@media screen and (max-width: 500px) {
			inset-block-start: calc(100% - 7rem);
			inset-inline-end: -9rem;
		}
		@media screen and (max-width: 480px) {
			inset-inline-end: -9rem;
		}
		@media screen and (max-width: 460px) {
			inset-inline-end: -10rem;
		}
		@media screen and (max-width: 450px) {
			inset-block-start: calc(100% - 7rem);
			inset-inline-end: -11rem;
		}
		@media screen and (max-width: 420px) {
			inset-block-start: calc(100% - 4rem);
			inset-inline-end: -8rem;
		}
	}
	&-right,
	&-left {
		&-image {
			z-index: 0;
			position: absolute;
			@media screen and (max-width: 1250px) {
				scale: 0.7;
			}
			@media screen and (max-width: 1024px) {
				scale: 0.5;
			}
			@media screen and (max-width: 500px) {
				scale: 0.35;
			}
		}
	}
	&-right {
		&-image {
			inset-inline-end: 0;
			max-width: 100%;
			max-height: 100%;
			inset-block-start: 4rem;
			@media screen and (max-width: 1250px) {
				inset-block-start: -4rem;
				inset-inline-end: -3rem;
			}
			@media screen and (max-width: 1024px) {
				inset-block-start: -10rem;
				inset-inline-end: -5.75rem;
			}
			@media screen and (max-width: 500px) {
				inset-block-start: -20rem;
				inset-inline-end: -6.3rem;
			}
		}
	}
	&-left {
		&-image {
			inset-inline-start: 0;
			max-width: 100%;
			max-height: 100%;
			inset-block-start: 12rem;
			@media screen and (max-width: 1250px) {
				inset-block-start: 6rem;
				inset-inline-start: -3rem;
			}
			@media screen and (max-width: 1024px) {
				inset-block-start: 2rem;
				inset-inline-start: -5.5rem;
			}
			@media screen and (max-width: 500px) {
				inset-block-start: 15rem;
				inset-inline-start: -6.75rem;
			}
		}
	}
	&-text {
		padding-inline: 2rem;
		display: flex;
		align-items: center;
		gap: 1rem;
		font-size: 2rem;
		font-weight: 300;
		font-family: 'Schoolbell';
		z-index: 1;
		position: relative;
		&::before {
			content: '';
			display: block;
			position: absolute;
			inset-block: 0.75rem;
			inset-inline: 1.5rem;
			background-color: #fff;
			z-index: -1;
		}
		@media screen and (max-width: 500px) {
			font-size: 1.75rem;
			// padding-inline: 0;
		}
		&-white {
			display: flex;
			align-items: center;
			gap: 1rem;
			font-size: 2rem;
			font-weight: 300;
			font-family: 'Schoolbell';
			z-index: 1;
			position: relative;
			&::before {
				content: '';
				display: block;
				position: absolute;
				inset-block: 0.75rem;
				inset-inline: 0rem -0.5rem;
				background-color: #fff;
				z-index: -1;
			}
			@media screen and (max-width: 500px) {
				font-size: 1.75rem;
				// padding-inline: 0;
			}
		}
	}
	&-title {
		padding-inline: 2rem;
		position: relative;
		font-size: 5rem;
		font-weight: 800;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.25rem;
		color: #fff;
		z-index: 1;
		text-shadow: -0.25rem -0.25rem 0 var(--font-color-primary), -0.25rem -0.1875rem 0 var(--font-color-primary),
			-0.25rem -0.125rem 0 var(--font-color-primary), -0.25rem -0.0625rem 0 var(--font-color-primary), -0.25rem 0 0 var(--font-color-primary),
			-0.25rem 0.0625rem 0 var(--font-color-primary), -0.25rem 0.125rem 0 var(--font-color-primary), -0.25rem 0.1875rem 0 var(--font-color-primary),
			-0.25rem 0.25rem 0 var(--font-color-primary), -0.25rem 0.3125rem 0 var(--font-color-primary), -0.25rem 0.375rem 0 var(--font-color-primary),
			-0.25rem 0.4375rem 0 var(--font-color-primary), -0.25rem 0.5rem 0 var(--font-color-primary), -0.25rem 0.5625rem 0 var(--font-color-primary),
			-0.25rem 0.625rem 0 var(--font-color-primary), -0.25rem 0.6875rem 0 var(--font-color-primary), -0.25rem 0.75rem 0 var(--font-color-primary),
			-0.25rem 0.8125rem 0 var(--font-color-primary), -0.25rem 0.875rem 0 var(--font-color-primary), 0.25rem -0.25rem 0 var(--font-color-primary),
			0.25rem -0.1875rem 0 var(--font-color-primary), 0.25rem -0.125rem 0 var(--font-color-primary), 0.25rem -0.0625rem 0 var(--font-color-primary),
			0.25rem 0 0 var(--font-color-primary), 0.25rem 0.0625rem 0 var(--font-color-primary), 0.25rem 0.125rem 0 var(--font-color-primary),
			0.25rem 0.1875rem 0 var(--font-color-primary), 0.25rem 0.25rem 0 var(--font-color-primary), 0.25rem 0.3125rem 0 var(--font-color-primary),
			0.25rem 0.375rem 0 var(--font-color-primary), 0.25rem 0.4375rem 0 var(--font-color-primary), 0.25rem 0.5rem 0 var(--font-color-primary),
			0.25rem 0.5625rem 0 var(--font-color-primary), 0.25rem 0.625rem 0 var(--font-color-primary), 0.25rem 0.6875rem 0 var(--font-color-primary),
			0.25rem 0.75rem 0 var(--font-color-primary), 0.25rem 0.8125rem 0 var(--font-color-primary), 0.25rem 0.875rem 0 var(--font-color-primary);
		@media screen and (max-width: 1024px) {
			font-size: 4.5rem;
		}
		@media screen and (max-width: 900px) {
			font-size: 4rem;
		}
		@media screen and (max-width: 769px) {
			max-width: 100%;
		}
		@media screen and (max-width: 600px) {
			max-width: 80%;
		}
		@media screen and (max-width: 500px) {
			max-width: 80%;
			padding-inline: 0;
		}
		@media screen and (max-width: 420px) {
			// padding-inline: 0;
			max-width: 90%;
		}
	}
	&-form {
		margin-block-start: 10rem;
		width: 50%;
		display: flex;
		flex-direction: column;
		gap: 4rem;
		@media screen and (max-width: 1024px) {
			width: 65%;
		}
		@media screen and (max-width: 500px) {
			width: 100%;
		}
		&-text {
			font-size: 2.25rem;
			font-family: 'Schoolbell';
			color: var(--font-color-primary);
			a {
				font-family: 'Schoolbell';
				font-size: 2.25rem;
				font-weight: 400;
				color: #0066cc;
				text-decoration: underline;
			}
			@media screen and (max-width: 769px) {
				font-size: 2rem;
			}
			&-small {
				font-size: 2rem;
				font-family: 'Schoolbell';
				color: var(--font-color-primary);
				position: relative;
				align-self: flex-start;
				z-index: 1;
				@media screen and (max-width: 769px) {
					font-size: 1.75rem;
				}
				&::after {
					content: '';
					display: inline-block;
					position: absolute;
					inset-block: 0.75rem;
					inset-inline: -0.5rem;
					background-color: #fff;
					z-index: -1;
				}
			}
		}
		&-input {
			&-container {
				display: flex;
				gap: 2rem;
				@media screen and (max-width: 769px) {
					flex-direction: column;
					gap: 1rem;
				}
				&-radio {
					display: flex;
					align-items: center;
					gap: 2rem;
					&-column {
						display: flex;
						flex-direction: column;
						gap: 2rem;
						label {
							position: absolute;
							padding-inline-start: 1rem;
							line-height: 1.2;
							&::after {
								top: 0 !important;
								transform: translateY(0.125rem) !important;
							}
						}
					}
				}
			}
			&-wrapper {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
			}
		}
		&-action {
			all: unset;
			cursor: pointer;
			align-self: center;
			padding: 1rem 4rem;
			background-color: #fff;
			border-radius: 2rem;
			font-size: 2rem;
			font-weight: 800;
			color: var(--font-color-primary);
			text-transform: uppercase;
			border: 4px solid var(--font-color-primary);
			box-shadow: 4px 4px 0px 2px var(--font-color-primary);
			transition: all 0.3s;
			&:hover {
				transform: translate(0.25rem, 0.25rem);
				box-shadow: 4px 4px 0px 0px var(--font-color-primary);
			}
		}
	}
}

.date-input-shape {
	background-color: #fff;
	position: relative;
	label {
		font-size: 2rem;
		color: #000c78;
		@media screen and (max-width: 500px) {
			font-size: 1.75rem;
		}
	}
}

.date {
	&-container {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		width: 100%;
	}
	&-label {
		font-size: 2rem;
		color: #000c78;
		font-family: 'Schoolbell';
		@media screen and (max-width: 500px) {
			font-size: 1.75rem;
		}
	}
	&-input-shape {
		background: #fff;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 6rem;
		opacity: 1;
		border-radius: 2.125rem;
		padding-inline: 2rem;
		position: relative;
		border: 4px solid #000c78;
		font-family: 'Schoolbell';
		font-size: 1.75rem;
		color: #000c78;
		cursor: pointer;
		@media screen and (max-width: 500px) {
			font-size: 1.5rem;
		}
	}
	&-calendar {
		border: 1px solid #000c78;
		position: absolute;
		inset-block-end: 100%;
		inset-inline-start: 0;
	}
}

.options {
	&-container {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		width: 100%;
		position: relative;
		.register-text {
			padding: 0;
			align-self: flex-start;
			color: #000c78;
			&::before {
				inset-inline: 0 -1rem;
			}
		}
	}
	&-label {
		font-size: 2rem;
		color: #000c78;
		font-family: 'Schoolbell';
		@media screen and (max-width: 500px) {
			font-size: 1.75rem;
		}
	}
}

.form-error {
	font-family: 'Schoolbell';
	color: #fb5b5b;
	font-size: 1.75rem;
	padding-inline-start: 1rem;
}
