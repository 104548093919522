.videos {
	padding-inline: calc(3 * var(--padding-x));
	padding-block-end: 14rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	position: relative;
	@media screen and (max-width: 900px) {
		padding-inline: var(--padding-x);
	}
	@media screen and (max-width: 769px) {
		padding-inline: var(--padding-x);
	}
	&-title {
		position: relative;
		font-size: 3.5rem;
		font-weight: 800;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		color: #fff;
		letter-spacing: 0.25rem;
		text-shadow: -4px -4px 0 var(--font-color-primary), -4px -3px 0 var(--font-color-primary), -4px -2px 0 var(--font-color-primary),
			-4px -1px 0 var(--font-color-primary), -4px 0 0 var(--font-color-primary), -4px 1px 0 var(--font-color-primary),
			-4px 2px 0 var(--font-color-primary), -4px 3px 0 var(--font-color-primary), -4px 4px 0 var(--font-color-primary),
			-4px 5px 0 var(--font-color-primary), -4px 6px 0 var(--font-color-primary), -4px 7px 0 var(--font-color-primary),
			-4px 8px 0 var(--font-color-primary), 0 -4px 0 var(--font-color-primary), 0 -3px 0 var(--font-color-primary), 0 -2px 0 var(--font-color-primary),
			0 -1px 0 var(--font-color-primary), 0 0 0 var(--font-color-primary), 0 1px 0 var(--font-color-primary), 0 2px 0 var(--font-color-primary),
			0 3px 0 var(--font-color-primary), 0 4px 0 var(--font-color-primary), 0 5px 0 var(--font-color-primary), 0 6px 0 var(--font-color-primary),
			0 7px 0 var(--font-color-primary), 0 8px 0 var(--font-color-primary), 4px -4px 0 var(--font-color-primary), 4px -3px 0 var(--font-color-primary),
			4px -2px 0 var(--font-color-primary), 4px -1px 0 var(--font-color-primary), 4px 0 0 var(--font-color-primary),
			4px 1px 0 var(--font-color-primary), 4px 2px 0 var(--font-color-primary), 4px 3px 0 var(--font-color-primary),
			4px 4px 0 var(--font-color-primary), 4px 5px 0 var(--font-color-primary), 4px 6px 0 var(--font-color-primary),
			4px 7px 0 var(--font-color-primary), 4px 8px 0 var(--font-color-primary);
		span {
			position: relative;
			text-shadow: none;
			font-size: 1.5rem;
			color: #28514f;
			font-weight: 600;
		}
		@media screen and (max-width: 1024px) {
			max-width: 70%;
		}
		@media screen and (max-width: 769px) {
			font-size: 2.5rem;
			line-height: 1.25;
			max-width: 65%;
		}
		@media screen and (max-width: 500px) {
			font-size: 2rem;
			line-height: 1.5;
			max-width: 80%;
		}
		@media screen and (max-width: 400px) {
			max-width: 95%;
		}
	}
	&-container {
		display: flex;
		gap: 2rem;
		@media screen and (max-width: 768px) {
			flex-direction: column;
		}
		&-video {
			position: relative;
			width: calc(calc(100% / 3) - calc(2 * 2rem / 3));
			aspect-ratio: 16 / 9;
			@media screen and (max-width: 768px) {
				width: 100%;
			}
			video {
				width: 100%;
				aspect-ratio: 16 / 9;
				object-fit: cover;
				cursor: pointer;
				border-radius: 3rem;
			}
			img {
				position: absolute;
				inset-block-start: 50%;
				inset-inline-start: 50%;
				transform: translate(-50%, -50%);
				cursor: pointer;
			}
		}
	}
	&-actions {
		position: absolute;
		inset-block-start: 1rem;
		display: flex;
		gap: 1rem;
		inset-inline-end: calc(3 * var(--padding-x));
		@media screen and (max-width: 900px) {
			inset-inline-end: var(--padding-x);
		}
		@media screen and (max-width: 769px) {
			inset-inline-end: var(--padding-x);
		}
	}
	&-action {
		all: unset;
		cursor: pointer;
		padding: 0.5rem 1.5rem;
		background-color: #fff;
		border-radius: 2rem;
		font-size: 1.5rem;
		font-weight: 800;
		color: var(--font-color-primary);
		text-transform: uppercase;
		border: 4px solid var(--font-color-primary);
		box-shadow: 4px 4px 0px 2px var(--font-color-primary);
		transition: all 0.3s;
		&:hover {
			transform: translate(0.25rem, 0.25rem);
			box-shadow: 4px 4px 0px 0px var(--font-color-primary);
		}
		&:disabled {
			opacity: 0.7;
			transform: translate(0, 0);
			box-shadow: 4px 4px 0px 2px var(--font-color-primary);
		}
		@media screen and (max-width: 500px) {
			padding: 0.25rem 0.75rem;
		}
	}
}
