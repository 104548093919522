.why {
	&-us {
		background-image: url('../../assets/icons/why-join.svg');
		background-repeat: no-repeat;
		background-size: cover;
		padding-block: 30rem 50rem;
		display: flex;
		flex-direction: column;
		gap: 20rem;
		@media screen and (max-width: 769px) {
			background-image: url('../../assets/icons/why-join-responsive.svg');
			padding-block: 30rem;
		}
		@media screen and (max-width: 500px) {
			padding-block: 20rem;
		}
	}
	&-join {
		padding-inline: calc(4 * var(--padding-x));
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 7rem;
		@media screen and (max-width: 900px) {
			padding-inline: var(--padding-x);
			gap: 2rem;
		}
		@media screen and (max-width: 769px) {
			flex-direction: column;
		}
		&-content {
			width: 45%;
			display: flex;
			flex-direction: column;
			gap: 4rem;
			@media screen and (max-width: 1024px) {
				width: 55%;
			}
			@media screen and (max-width: 769px) {
				width: 100%;
				gap: 2rem;
			}
			&-title {
				font-size: 3.5rem;
				font-weight: 800;
				text-transform: uppercase;
				color: var(--font-color-secondary);
				letter-spacing: 0.25rem;
				text-shadow: -4px -4px 0 var(--font-color-primary), -4px -3px 0 var(--font-color-primary), -4px -2px 0 var(--font-color-primary),
					-4px -1px 0 var(--font-color-primary), -4px 0 0 var(--font-color-primary), -4px 1px 0 var(--font-color-primary),
					-4px 2px 0 var(--font-color-primary), -4px 3px 0 var(--font-color-primary), -4px 4px 0 var(--font-color-primary),
					-4px 5px 0 var(--font-color-primary), -4px 6px 0 var(--font-color-primary), -4px 7px 0 var(--font-color-primary),
					-4px 8px 0 var(--font-color-primary), 0 -4px 0 var(--font-color-primary), 0 -3px 0 var(--font-color-primary),
					0 -2px 0 var(--font-color-primary), 0 -1px 0 var(--font-color-primary), 0 0 0 var(--font-color-primary), 0 1px 0 var(--font-color-primary),
					0 2px 0 var(--font-color-primary), 0 3px 0 var(--font-color-primary), 0 4px 0 var(--font-color-primary), 0 5px 0 var(--font-color-primary),
					0 6px 0 var(--font-color-primary), 0 7px 0 var(--font-color-primary), 0 8px 0 var(--font-color-primary),
					4px -4px 0 var(--font-color-primary), 4px -3px 0 var(--font-color-primary), 4px -2px 0 var(--font-color-primary),
					4px -1px 0 var(--font-color-primary), 4px 0 0 var(--font-color-primary), 4px 1px 0 var(--font-color-primary),
					4px 2px 0 var(--font-color-primary), 4px 3px 0 var(--font-color-primary), 4px 4px 0 var(--font-color-primary),
					4px 5px 0 var(--font-color-primary), 4px 6px 0 var(--font-color-primary), 4px 7px 0 var(--font-color-primary),
					4px 8px 0 var(--font-color-primary);
				@media screen and (max-width: 769px) {
					font-size: 3rem;
				}
			}
			&-text {
				font-family: 'Schoolbell';
				font-size: 1.75rem;
			}
			div {
				display: flex;
				gap: 1.5rem;
				img {
					width: 9rem;
					object-fit: contain;
					@media screen and (max-width: 900px) {
						width: 5rem;
						align-self: flex-start;
						margin-block-start: 0.5rem;
					}
					@media screen and (max-width: 769px) {
						align-self: center;
						margin-block-start: 0;
					}
					@media screen and (max-width: 500px) {
						width: 5rem;
						align-self: flex-start;
						margin-block-start: 0.5rem;
					}
				}
				p {
					display: flex;
					flex-direction: column;
					gap: 1rem;
					font-size: 1.75rem;
					font-family: 'Schoolbell';
					color: var(--font-color-primary);
					a {
						align-self: flex-start;
						display: flex;
						align-items: center;
						font-size: 1.5rem;
						text-transform: uppercase;
						gap: 1rem;
						font-family: 'Schoolbell';
						text-decoration: none;
						color: #da494c;
						img {
							align-self: center !important;
							width: 1.5rem !important;
							object-fit: contain;
						}
					}
				}
			}
		}
		&-action {
			all: unset;
			margin-block-start: -2rem;
			cursor: pointer;
			width: auto;
			align-self: center;
			padding: 1rem 2.5rem;
			background-color: #fff;
			border-radius: 2rem;
			font-size: 1.5rem;
			font-weight: 800;
			color: var(--font-color-primary);
			text-transform: uppercase;
			border: 4px solid var(--font-color-primary);
			box-shadow: 4px 4px 0px 2px var(--font-color-primary);
			transition: all 0.3s;
			&:hover {
				transform: translate(0.25rem, 0.25rem);
				box-shadow: 4px 4px 0px 0px var(--font-color-primary);
			}
			@media screen and (max-width: 900px) {
				margin-block-start: 1rem;
			}
		}
		&-image {
			width: 40%;
			@media screen and (max-width: 769px) {
				width: 75%;
			}
			@media screen and (max-width: 500px) {
				width: 100%;
			}
			img {
				width: 100%;
			}
		}
	}
}

.welcome {
	padding-inline: calc(4 * var(--padding-x));
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 7rem;
	&-content {
		width: 45%;
		display: flex;
		flex-direction: column;
		gap: 4rem;
		&-title {
			font-size: 3.5rem;
			font-weight: 800;
			text-transform: uppercase;
			color: var(--font-color-secondary);
			letter-spacing: 0.25rem;
			text-shadow: -4px -4px 0 var(--font-color-primary), -4px -3px 0 var(--font-color-primary), -4px -2px 0 var(--font-color-primary),
				-4px -1px 0 var(--font-color-primary), -4px 0 0 var(--font-color-primary), -4px 1px 0 var(--font-color-primary),
				-4px 2px 0 var(--font-color-primary), -4px 3px 0 var(--font-color-primary), -4px 4px 0 var(--font-color-primary),
				-4px 5px 0 var(--font-color-primary), -4px 6px 0 var(--font-color-primary), -4px 7px 0 var(--font-color-primary),
				-4px 8px 0 var(--font-color-primary), 0 -4px 0 var(--font-color-primary), 0 -3px 0 var(--font-color-primary),
				0 -2px 0 var(--font-color-primary), 0 -1px 0 var(--font-color-primary), 0 0 0 var(--font-color-primary), 0 1px 0 var(--font-color-primary),
				0 2px 0 var(--font-color-primary), 0 3px 0 var(--font-color-primary), 0 4px 0 var(--font-color-primary), 0 5px 0 var(--font-color-primary),
				0 6px 0 var(--font-color-primary), 0 7px 0 var(--font-color-primary), 0 8px 0 var(--font-color-primary), 4px -4px 0 var(--font-color-primary),
				4px -3px 0 var(--font-color-primary), 4px -2px 0 var(--font-color-primary), 4px -1px 0 var(--font-color-primary),
				4px 0 0 var(--font-color-primary), 4px 1px 0 var(--font-color-primary), 4px 2px 0 var(--font-color-primary),
				4px 3px 0 var(--font-color-primary), 4px 4px 0 var(--font-color-primary), 4px 5px 0 var(--font-color-primary),
				4px 6px 0 var(--font-color-primary), 4px 7px 0 var(--font-color-primary), 4px 8px 0 var(--font-color-primary);
		}
		&-text {
			font-family: 'Schoolbell';
			font-size: 1.75rem;
		}
		div {
			display: flex;
			gap: 1.5rem;
			img {
				width: 5rem;
				object-fit: contain;
			}
			p {
				display: flex;
				flex-direction: column;
				position: relative;
				gap: 0.25rem;
				span {
					position: relative;
					font-size: 1.75rem;
					align-self: start;
					font-family: 'Schoolbell';
					color: #01708b;
					z-index: 1;
					&::before {
						content: '';
						position: absolute;
						display: flex;
						inset: 0;
						width: 98%;
						height: 70%;
						transform: translate(1%, 30%);
						background-color: #00efe03b;
						z-index: -1;
					}
				}
			}
		}
	}
}
