.discounts {
	margin-block-start: -8rem;
	display: flex;
	flex-direction: column;
	gap: 5rem;
	align-items: center;
	z-index: 1;
	&-title {
		// max-width: 30%;
		position: relative;
		text-align: center;
		font-size: 3.5rem;
		font-weight: 800;
		text-transform: uppercase;
		color: #fff;
		letter-spacing: 0.25rem;
		text-shadow: -4px -4px 0 var(--font-color-primary), -4px -3px 0 var(--font-color-primary), -4px -2px 0 var(--font-color-primary),
			-4px -1px 0 var(--font-color-primary), -4px 0 0 var(--font-color-primary), -4px 1px 0 var(--font-color-primary),
			-4px 2px 0 var(--font-color-primary), -4px 3px 0 var(--font-color-primary), -4px 4px 0 var(--font-color-primary),
			-4px 5px 0 var(--font-color-primary), -4px 6px 0 var(--font-color-primary), -4px 7px 0 var(--font-color-primary),
			-4px 8px 0 var(--font-color-primary), 0 -4px 0 var(--font-color-primary), 0 -3px 0 var(--font-color-primary), 0 -2px 0 var(--font-color-primary),
			0 -1px 0 var(--font-color-primary), 0 0 0 var(--font-color-primary), 0 1px 0 var(--font-color-primary), 0 2px 0 var(--font-color-primary),
			0 3px 0 var(--font-color-primary), 0 4px 0 var(--font-color-primary), 0 5px 0 var(--font-color-primary), 0 6px 0 var(--font-color-primary),
			0 7px 0 var(--font-color-primary), 0 8px 0 var(--font-color-primary), 4px -4px 0 var(--font-color-primary), 4px -3px 0 var(--font-color-primary),
			4px -2px 0 var(--font-color-primary), 4px -1px 0 var(--font-color-primary), 4px 0 0 var(--font-color-primary),
			4px 1px 0 var(--font-color-primary), 4px 2px 0 var(--font-color-primary), 4px 3px 0 var(--font-color-primary),
			4px 4px 0 var(--font-color-primary), 4px 5px 0 var(--font-color-primary), 4px 6px 0 var(--font-color-primary),
			4px 7px 0 var(--font-color-primary), 4px 8px 0 var(--font-color-primary);
		// @media screen and (max-width: 1900px) {
		// 	max-width: 35%;
		// }
		// @media screen and (max-width: 1420px) {
		// 	max-width: 45%;
		// }
		@media screen and (max-width: 1024px) {
			font-size: 3rem;
			// max-width: 50%;
		}
		// @media screen and (max-width: 769px) {
		// 	max-width: 70%;
		// }
		@media screen and (max-width: 500px) {
			font-size: 2.5rem;
			// max-width: 75%;
			line-height: 1.5;
		}
	}
	&-cards {
		padding-inline: 5%;
		display: flex;
		justify-content: space-between;
		gap: 1.5rem;
		@media screen and (max-width: 1024px) {
			flex-wrap: wrap;
			justify-content: center;
		}
		@media screen and (max-width: 550px) {
			flex-direction: column;
			gap: 5rem;
		}
	}
	&-card {
		width: 100%;
		max-width: 30rem;
		padding: 5rem 4rem;
		background-color: #fff;
		border-radius: 5rem;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		@media screen and (max-width: 1400px) {
			padding: 3rem 2rem;
		}
		@media screen and (max-width: 1024px) {
			padding: 5rem 4rem;
			width: calc(50% - 2rem);
		}
		@media screen and (max-width: 550px) {
			padding: 5rem 4rem;
			width: 100%;
		}
		&-title {
			font-family: 'Schoolbell';
			font-size: 1.75rem;
			font-weight: 400;
			z-index: 1;
			position: relative;
			&-image {
				position: absolute;
				z-index: -1;
				inset-block-end: 50%;
				transform: translateY(50%);
			}
		}
		&-text {
			font-family: 'Schoolbell';
			font-size: 1.5rem;
			color: var(--font-color-primary);
			flex-grow: 1;
			&-list {
				list-style: lower-alpha;
				padding: 0;
				font-family: 'Schoolbell';
				color: var(--font-color-primary);
				font-size: 1.25rem;
				li {
					font-size: 1.5rem;
					font-weight: 500;
					font-family: 'Schoolbell';
					color: var(--font-color-primary);
					span {
						font-family: 'Schoolbell';
						font-weight: 500;
						font-size: 1.5rem;
						color: var(--font-color-secondary);
					}
				}
			}
		}
		&-link {
			align-self: flex-start;
			display: flex;
			align-items: center;
			font-size: 1.5rem;
			text-transform: uppercase;
			gap: 1rem;
			font-family: 'Schoolbell';
			text-decoration: none;
			color: #da494c;
			margin-block-start: auto;
			img {
				align-self: center !important;
				width: 1.5rem !important;
				object-fit: contain;
			}
		}
	}
}
