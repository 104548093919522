.layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100vw;
	width: 100%;
	overflow: hidden;
	background-color: var(--bg-color-primary);
	&-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
}
