.navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-inline: var(--padding-x);
	&-logo {
		transform: translateX(50%);
		width: 10rem;
		position: relative;
		z-index: 1;
		@media screen and (max-width: 1250px) {
			width: 7.5rem;
		}
		&::after {
			position: absolute;
			content: '';
			display: block;
			width: 250%;
			height: 150%;
			background-image: url(../../assets/icons/navbar-logo-curve.svg);
			background-repeat: no-repeat;
			background-size: contain;
			inset-block-start: 0;
			inset-inline-end: 50%;
			transform: translateX(50%);
			z-index: -1;
		}
		&-icon {
			max-width: 100%;
			object-fit: contain;
			cursor: pointer;
		}
	}
	&-links {
		padding-inline: 0;
		list-style: none;
		display: flex;
		align-items: center;
		gap: 8rem;
		margin-inline-start: 8rem;
		@media screen and (max-width: 1250px) {
			gap: 5rem;
		}
		@media screen and (max-width: 950px) {
			gap: 3rem;
		}
		@media screen and (max-width: 820px) {
			gap: 1rem;
		}
		@media screen and (max-width: 769px) {
			display: none;
		}
	}
	&-link {
		position: relative;
		text-transform: uppercase;
		font-size: 1.375rem;
		font-weight: 800;
		color: var(--font-color-primary);
		cursor: pointer;
		span {
			position: absolute;
			width: 100%;
			height: auto;
			inset-inline-end: 0;
			inset-block-end: 100%;
			text-align: end;
			color: var(--font-color-primary);
			font-size: 0.875rem;
			font-weight: 600;
			&::before {
				content: '';
				position: absolute;
				display: flex;
				align-items: center;
				width: 0.65rem;
				aspect-ratio: 1;
				background-color: var(--font-color-secondary);
				border-radius: 50%;
				inset-inline-end: 50%;
				inset-block-start: 50%;
				transform: translateY(-50%);
			}
		}
	}
	&-action {
		all: unset;
		cursor: pointer;
		padding: 1rem 2.5rem;
		background-color: #fff;
		border-radius: 2rem;
		font-size: 1.5rem;
		font-weight: 800;
		color: var(--font-color-primary);
		text-transform: uppercase;
		border: 4px solid var(--font-color-primary);
		box-shadow: 4px 4px 0px 2px var(--font-color-primary);
		transition: all 0.3s;
		&:hover {
			transform: translate(0.25rem, 0.25rem);
			box-shadow: 4px 4px 0px 0px var(--font-color-primary);
		}
		@media screen and (max-width: 769px) {
			display: none;
		}
	}
	&-menu {
		position: fixed;
		z-index: 100;
		background-color: #fff;
		inset: 0;
		inset-inline-start: 40%;
		padding: 5rem;
		animation: openMenu 0.3s ease-in-out forwards;
		&-close {
			cursor: pointer;
			position: absolute;
			width: 3rem;
			inset-block-start: 1.5rem;
			inset-inline-end: 1.5rem;
		}
		&-links {
			list-style: none;
			padding: 0;
			display: flex;
			flex-direction: column;
			gap: 2rem;
		}
		&-link {
			position: relative;
			font-size: 2rem;
			text-transform: uppercase;
			color: var(--font-color-primary);
			cursor: pointer;
			display: flex;
			gap: 1rem;
			width: max(max-content, 100%);
			justify-content: space-between;
			// flex-direction: column;
			img {
				width: 2rem;
				height: 2rem;
			}
			&:last-child {
				align-self: center;
			}
			span {
				font-size: 1.25rem;
				align-self: flex-end;
				position: relative;
				display: flex;
				align-items: center;
				gap: 0.5rem;
				&::before {
					content: '';
					display: flex;
					align-items: center;
					width: 0.65rem;
					aspect-ratio: 1;
					background-color: var(--font-color-secondary);
					border-radius: 50%;
				}
			}
			&-dropdown-menu {
				position: absolute;
				top: calc(100% + 1rem);
				left: 0;
				width: max-content;
				z-index: 1000;
				background-color: #fff;
				list-style: none;
				padding: 1rem;
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				border-radius: 1rem;
				font-size: 2rem;
				color: var(--font-color-primary);
				li {
					cursor: pointer;
				}
				@media screen and (max-width: 769px) {
					position: static;
					width: 100%;
					padding: 0;
					padding-bottom: 2rem;
					border-radius: 0;
					border-bottom: 1px solid var(--font-color-primary);
				}
			}
			&-action {
				all: unset;
				cursor: pointer;
				padding: 1rem 2.5rem;
				margin-block-start: 2rem;
				background-color: #fff;
				border-radius: 2rem;
				font-size: 1.5rem;
				font-weight: 800;
				color: var(--font-color-primary);
				text-transform: uppercase;
				border: 4px solid var(--font-color-primary);
				box-shadow: 4px 4px 0px 2px var(--font-color-primary);
				transition: all 0.3s;
				&:hover {
					transform: translate(0.25rem, 0.25rem);
					box-shadow: 4px 4px 0px 0px var(--font-color-primary);
				}
			}
		}
		@media screen and (max-width: 575px) {
			padding: 5rem 3rem;
			inset-inline-start: 20%;
		}
		&-action {
			all: unset;
			box-sizing: border-box;
			cursor: pointer;
			display: none;
			height: 0.5rem;
			width: 2.5rem;
			border-radius: 0.5rem;
			background-color: #fff;
			position: relative;
			&::after,
			&::before {
				box-sizing: border-box;
				content: '';
				display: block;
				box-sizing: content-box;
				height: 0.5rem;
				width: 2.5rem;
				border-radius: 0.5rem;
				background-color: #fff;
				position: absolute;
				inset-block-start: 0;
				inset-inline-start: 50%;
			}
			&::before {
				transform: translate(-50%, 0.75rem);
			}
			&::after {
				transform: translate(-50%, -0.75rem);
			}
			@media screen and (max-width: 769px) {
				display: block;
			}
		}
	}
}

@keyframes openMenu {
	from {
		inset-inline-start: 100%;
	}
	to {
		inset-inline-start: 40%;
	}
}

.rotate {
	transform: rotate(180deg);
}
