.snackbar {
	position: fixed;
	inset-block-end: 1rem;
	inset-inline-end: -100%;
	background: #29c9c1;
	border-radius: 0.5rem;
	padding: 1rem 1rem 1rem 2rem;
	transition: all 350ms ease-in-out;
	display: flex;
	align-items: center;
	gap: 1rem;
	z-index: 1000;
	&-show {
		inset-inline-end: 1rem;
	}
	&-error {
		background-image: none;
		background-color: #fb5b5b;
	}
	&-close {
		all: unset;
		cursor: pointer;
		width: 2rem;
		height: 2rem;
		&-icon {
			width: 2rem;
			height: 2rem;
		}
	}
	&-text {
		color: #fff;
		font-size: 1.75rem;
	}
}
