.ready {
	margin-block-start: 10rem;
	padding-block: 2rem 10rem;
	position: relative;
	padding-inline: calc(2 * var(--padding-x));
	display: flex;
	flex-direction: column;
	gap: 5rem;
	align-items: center;
	&-image {
		position: absolute;
		inset-inline-end: 0;
		transform: translateX(50%);
		@media screen and (max-width: 1250px) {
			max-width: 17.5rem;
			inset-block-start: 20%;
		}
		@media screen and (max-width: 1200px) {
			max-width: 17.5rem;
			inset-block-start: 45%;
		}
		@media screen and (max-width: 1024px) {
			max-width: 17.5rem;
			inset-block-start: 85%;
			transform: translate(40%, -40%);
		}
		@media screen and (max-width: 769px) {
			max-width: 30%;
			inset-block-start: 100%;
			transform: translate(40%, -40%);
		}
		@media screen and (max-width: 500px) {
			max-width: 8rem;
			inset-block-start: 100%;
			transform: translate(40%, -40%);
		}
	}
	&-right {
		position: absolute;
		max-width: 100%;
		inset-inline-end: 0;
		inset-block-start: 0;
		@media screen and (max-width: 1024px) {
			scale: 0.6;
			inset-block-start: -2rem;
			inset-inline-end: -3.45rem;
		}
		@media screen and (max-width: 769px) {
			scale: 0.4;
			inset-block-start: -8rem;
			inset-inline-end: -5.65rem;
		}
		@media screen and (max-width: 500px) {
			scale: 0.3;
			inset-block-start: -8rem;
			inset-inline-end: -6.65rem;
		}
	}
	&-left {
		max-width: 100%;
		position: absolute;
		inset-inline-start: 0%;
		inset-block-start: -60%;
		@media screen and (max-width: 1024px) {
			scale: 0.6;
			inset-block-start: -60%;
			inset-inline-start: -3.45rem;
		}
		@media screen and (max-width: 769px) {
			scale: 0.4;
			inset-block-start: -90%;
			inset-inline-start: -7.65rem;
		}
		@media screen and (max-width: 500px) {
			scale: 0.3;
			inset-block-start: -70%;
			inset-inline-start: -7.65rem;
		}
	}
	&-title {
		position: relative;
		max-width: 52%;
		text-align: center;
		font-size: 3.5rem;
		font-weight: 800;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		color: #fff;
		letter-spacing: 0.25rem;
		text-shadow: -4px -4px 0 var(--font-color-primary), -4px -3px 0 var(--font-color-primary), -4px -2px 0 var(--font-color-primary),
			-4px -1px 0 var(--font-color-primary), -4px 0 0 var(--font-color-primary), -4px 1px 0 var(--font-color-primary),
			-4px 2px 0 var(--font-color-primary), -4px 3px 0 var(--font-color-primary), -4px 4px 0 var(--font-color-primary),
			-4px 5px 0 var(--font-color-primary), -4px 6px 0 var(--font-color-primary), -4px 7px 0 var(--font-color-primary),
			-4px 8px 0 var(--font-color-primary), 0 -4px 0 var(--font-color-primary), 0 -3px 0 var(--font-color-primary), 0 -2px 0 var(--font-color-primary),
			0 -1px 0 var(--font-color-primary), 0 0 0 var(--font-color-primary), 0 1px 0 var(--font-color-primary), 0 2px 0 var(--font-color-primary),
			0 3px 0 var(--font-color-primary), 0 4px 0 var(--font-color-primary), 0 5px 0 var(--font-color-primary), 0 6px 0 var(--font-color-primary),
			0 7px 0 var(--font-color-primary), 0 8px 0 var(--font-color-primary), 4px -4px 0 var(--font-color-primary), 4px -3px 0 var(--font-color-primary),
			4px -2px 0 var(--font-color-primary), 4px -1px 0 var(--font-color-primary), 4px 0 0 var(--font-color-primary),
			4px 1px 0 var(--font-color-primary), 4px 2px 0 var(--font-color-primary), 4px 3px 0 var(--font-color-primary),
			4px 4px 0 var(--font-color-primary), 4px 5px 0 var(--font-color-primary), 4px 6px 0 var(--font-color-primary),
			4px 7px 0 var(--font-color-primary), 4px 8px 0 var(--font-color-primary);
		span {
			position: relative;
			text-shadow: none;
			font-size: 1.5rem;
			color: #28514f;
			font-weight: 600;
			&::after {
				content: '';
				position: absolute;
				width: 1rem;
				height: 1rem;
				background-color: #19ad43;
				border-radius: 100%;
				inset-block-start: 50%;
				transform: translate(50%, -50%);
			}
			&::before {
				content: '';
				position: absolute;
				width: 1rem;
				height: 1rem;
				background-color: #19ad43;
				border-radius: 100%;
				inset-block-start: 50%;
				transform: translate(-150%, -50%);
			}
		}
		@media screen and (max-width: 1024px) {
			max-width: 70%;
		}
		@media screen and (max-width: 769px) {
			font-size: 2.5rem;
			line-height: 1.25;
			max-width: 65%;
		}
		@media screen and (max-width: 500px) {
			font-size: 2rem;
			line-height: 1.5;
			max-width: 80%;
		}
		@media screen and (max-width: 400px) {
			max-width: 95%;
		}
	}
	&-text {
		max-width: 50%;
		text-align: center;
		font-family: 'Schoolbell';
		font-size: 1.75rem;
		@media screen and (max-width: 1024px) {
			max-width: 80%;
		}
		@media screen and (max-width: 769px) {
			font-size: 1.5rem;
			max-width: 80%;
			max-width: none;
		}
		@media screen and (max-width: 500px) {
			font-size: 1.5rem;
			max-width: none;
		}
	}
	&-action {
		all: unset;
		cursor: pointer;
		padding: 1rem 2.5rem;
		background-color: #fff;
		border-radius: 2rem;
		font-size: 1.5rem;
		font-weight: 800;
		color: var(--font-color-primary);
		text-transform: uppercase;
		border: 4px solid var(--font-color-primary);
		box-shadow: 4px 4px 0px 2px var(--font-color-primary);
		transition: all 0.3s;
		&:hover {
			transform: translate(0.25rem, 0.25rem);
			box-shadow: 4px 4px 0px 0px var(--font-color-primary);
		}
	}
}
