.container {
	border-radius: 6px;
	height: 45px;
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	* {
		font-family: 'Schoolbell';
		font-size: 2rem;
	}
	.radio-input {
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}

	.radio-input-lable {
		cursor: pointer;
		border-radius: 6px;
		top: 0;
		left: 0;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		box-sizing: border-box;
		flex-direction: column;
		&-image {
			border-radius: 0.25rem;
		}
		&-container {
			display: flex;
			align-items: center;
			gap: 0.5rem;
		}
		.radio-input-content {
			// width: 100%;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-inline-start: 4rem;
			.radio-input-lable-text {
				font-size: 2rem;
				font-weight: 400;
				color: var(--font-color-primary);
			}
			.radio-input-lable-image {
				height: 1.5rem;
			}
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: 2rem;
			width: 2rem;
			width: 2rem;
			height: 2rem;
			border: 4px solid var(--font-color-primary);
			border-radius: 50%;
			background-color: #fff;
			box-sizing: border-box;
		}
	}
	.radio-input:checked ~ .radio-input-lable {
		&:after {
			border: 4px solid #ffffff;
			background: var(--font-color-primary);
			outline: 4px solid var(--font-color-primary);
		}
	}
	.radio-input {
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}
