@font-face {
  font-family: 'Gemunu Libre';
  src: url('./fonts/GemunuLibre/GemunuLibre-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Gemunu Libre';
  src: url('./fonts/GemunuLibre/GemunuLibre-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Gemunu Libre';
  src: url('./fonts/GemunuLibre/GemunuLibre-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gemunu Libre';
  src: url('./fonts/GemunuLibre/GemunuLibre-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gemunu Libre';
  src: url('./fonts/GemunuLibre/GemunuLibre-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Gemunu Libre';
  src: url('./fonts/GemunuLibre/GemunuLibre-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Gemunu Libre';
  src: url('./fonts/GemunuLibre/GemunuLibre-ExtraBold.ttf') format('truetype');
  font-weighwt: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Schoolbell';
  src: url('./fonts/Schoolbell/Schoolbell-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

:root {
  --bg-color-primary: #f9d012;
  --font-color-primary: #2C2455;
  --font-color-secondary: #FF6B25;
  --font-color-accent: #B343D3;
  --padding-x: 4rem;
  font-size: 16px;
  /* mobile screen */
  @media screen and (max-width: 576px) {
    --padding-x: 2rem;
    font-size: 12px;
  }
  /* tablet screen */
  @media screen and (max-width: 768px) {
    --padding-x: 2rem;
    font-size: 12px;
  }
  /* desktop screen */
  @media screen and (min-width: 768px) {
    --padding-x: 2rem;
    font-size: 13px;
  }
  /* large desktop screen */
  @media screen and (min-width: 1024px) {
    font-size: 14px;
  }
  /* extra large desktop screen */
  @media screen and (min-width: 1420px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1920px){
    zoom: 90%;
  }
  @media screen and (max-width: 1920px){
    zoom: 80%;
  }
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Gemunu Libre', sans-serif;
}

*:focus,*:focus-visible,*:focus-within {
  outline: none !important;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input, textarea, button, select, a {
  -webkit-tap-highlight-color: transparent !important;
}

a {
  cursor: pointer;
}

#menu- {
  /* .MuiPaper-root {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    transform-origin: 0 0 !important;
  } */
  /* transform: translate(17%,72%) !important */
}